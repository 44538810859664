import { Button } from 'antd';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { getSummaryFieldValue } from '../../utils';

type CSVHeaderItemType = {
    label: string,
    key: string,
    type: string
}

type CSVDownLoaderType = {
    data: any,
    headers?: CSVHeaderItemType[],
    fileName?: string,
    className?: string
}

const CSVDownLoader = ({ data, headers, className, fileName }: CSVDownLoaderType) => {
    const getData = () => {
        const toBeFormatColumns = headers?.filter(header => header.type === 'date' || header.type === 'phone');
        return data.map((item: { [x: string]: any; }) => {
            const formattedValues = toBeFormatColumns?.reduce((value :any, column: CSVHeaderItemType) => {
                value[column.key] = getSummaryFieldValue(column.type, item[column.key]);
                return value;
            }, {});
            return {
                ...item,
                ...formattedValues
            }
        });
    }
    return (
        <CSVLink data={getData()} filename={fileName} headers={headers}>
            <Button disabled={data.length === 0} className={className} icon={<DeliveredProcedureOutlined />}>
                Export
            </Button>
        </CSVLink>
    )
};

export default CSVDownLoader;