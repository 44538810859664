import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BarChartOutlined } from '@ant-design/icons';
import { Typography, Flex, Collapse } from "antd";
import { getHourlySubmissions } from './actions';
import { authContext } from '../../context/auth';
import { ChartDataType } from '../../types';
import { Column } from '@ant-design/charts';

type PropsType = {
    formId: string,
    status?: string,
    onRefresh?: boolean,
    onCollapse?: () => void
}

export default function HourlySubmissionChart({ formId, status, onCollapse , onRefresh }: PropsType) {
    const [data, setData] = useState<ChartDataType[]>([]);
    const [isactive, setActive] = useState<boolean>(false);
    const { authState } = useContext(authContext);
    const { user } = authState;

    const loadChart = useCallback(async () => {
        let hasData = false;
        const data = await getHourlySubmissions(user['custom:orgId'], formId!, status!);
        const transformedData = data.map((item: ChartDataType) => {
            const hour = new Date(item.datetime).getHours();

            if (item.count > 0) hasData = true;

            return {
                datetime: `${hour}${hour < 12 ? 'a' : 'p'}`,
                count: item.count,
            };
        });
        setData(transformedData);
        setActive(hasData);

    }, [formId, user, status]);

    useEffect(() => {
        formId && loadChart();
    }, [loadChart, formId]);

    if (onRefresh) loadChart();

    const config = {
        data: data,
        xField: 'datetime',
        yField: 'count',
        minColumnWidth: 20,
        maxColumnWidth: 30,
        columnStyle: {
            radius: [6, 6, 0, 0],
        },
        tooltip: {
            formatter: (item: any) => {
                return {
                    name: `${item.datetime}`,
                    value: `${item.count}`,
                };
            },
        },
        label: {
            style: { opacity: 0.5, fontSize:11}
        },
        yAxis: {
            grid: { line: { style: { lineWidth: 0 } } },
            title: { text: "" },
            label: { style: { fontSize: 0 } }
        },
        xAxis: {
            title: { text: "" },
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        autoFit: true,
    };

    const chart =
        <Flex className="chart">
            <Column
                {...config}
                height={110}
                color='#FFAC1C'
            />
        </Flex>;

    const onCollapseChart = () => {
        setActive(!isactive);
        if (onCollapse) onCollapse();
    };

    if (onCollapse) onCollapse();

    return (
        <Collapse
            onChange={onCollapseChart}
            ghost
            className="chart-accordion margin-bottom"
            defaultActiveKey={['1']}
            {...(isactive ? { activeKey: ['1'] } : { activeKey: [''] })}
            items={[{
                label: <Typography className="chart-title"><BarChartOutlined className="margin-right"></BarChartOutlined>Hourly Submissions Count for Today</Typography>,
                children: chart,
                key: '1'
            }]}
        />
    );
}