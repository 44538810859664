
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

export const toDateString = (datetime: string) => datetime ? new Date(datetime).toDateString() : '';

export const toTimeString = (datetime: string) => datetime ? dayjs(new Date(datetime)).format('hh:mm a') : '';

export const toDateTimeString = (datetime: string) => datetime ? dayjs(new Date(datetime)).format('DD/MM/YYYY').concat(' at ').concat(dayjs(new Date(datetime)).format('hh:mm A')) : '';

export const toDateTimeISOString = (datetime: string): string => {
    return new Date(datetime).toISOString();    
};

export const subtractDaysFromNow = (days: number) => {
    return new Date(new Date().setDate(new Date().getDate() - days)).toUTCString()
}

export const dayJs = (date: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string, strict?: boolean) => {
    dayjs.extend(weekday);
    dayjs.extend(localeData);
    return dayjs(date, format, locale, strict);   
}

export const startOfTheDay = () => {
    const start = new Date();
    start.setUTCHours(0, 0, 0, 0);
    return start.toISOString();
}

export const endOfTheDay = () => {
    const end = new Date();
    end.setUTCHours(23,59, 59, 999);
    return end.toISOString();
}

export const utcStartOfTheDay = () => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    return start.toISOString();
}

export const utcEndOfTheDay = () => {
    const end = new Date();
    end.setHours(23,59, 59, 999);
    return end.toISOString();
}

