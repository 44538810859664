import React, { ReactElement } from 'react';

type PropsType = {
    children: ReactElement,
    elemRef?: React.RefObject<HTMLDivElement>
}

export default function ScrollView({ children, elemRef }: PropsType) {
    return (
        <div className='scroll-view full-height' ref={elemRef}>
            {children}
        </div>
    );
}