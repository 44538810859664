import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { PrinterOutlined} from '@ant-design/icons';
import { message, Button } from 'antd';
import ReactToPrint from 'react-to-print';
import { Loading, ViewLayout, SubmissionDetailsView } from '../../components';
import { getAssetDetails } from './actions';
import { SubmissionDetails } from '../../types';
import { ASSET_TYPE } from '../../constants';
import { ReturnAsset } from './DetailViewActions';

export default function AssetDetailsView() {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [details, setDetails] = useState<SubmissionDetails | undefined>();
    const { userId, submissionId } = useParams();
    const detailsRef = useRef<HTMLDivElement>(null);

    const loadDetails = useCallback(async () => {
        try {
            setLoading(true);
            if (!userId || !submissionId) {
                return;
            }
            const result = await getAssetDetails(userId, submissionId);
            setDetails(result);
        } catch (e) {
            message.error('Error in loading asset checkout, Please retry again.');
        } finally {
            setLoading(false);
        }
    }, [userId, submissionId]);

    useEffect(() => {
        loadDetails();
    }, [loadDetails]);

    const onAssetReturn = async () => {
        await loadDetails();
    };

    const detailViewActions = [];

    if (details?.submission.status === ASSET_TYPE.ACTIVE.status) {
        detailViewActions.push(<ReturnAsset userId={userId!} submissionId={submissionId!} onAssetReturn={onAssetReturn} />);
    }

    const title = details?.submission?.sessionFormName ?? details?.submission.name ?? details?.submission.formTitle ?? 'Asset Details';
    
    const content = isLoading ? <Loading /> : <SubmissionDetailsView ref={detailsRef} details={details} actions={detailViewActions} title={title} />;

    return (
        <ViewLayout
            showSearch={false}
            showTimeFilter={false}
            title={title}
            showBackButton={true}
        >
            <div className="pad-top full-width full-height">
                {!isLoading && (
                    <div className='print-report'>
                        <ReactToPrint
                            trigger={() => <Button type="primary" className='refresh-btn border-radius margin-bottom margin-top' style={{ marginTop: '15px', marginBottom: '20px' }} icon={<PrinterOutlined />}> Print</Button>}
                            content={() => detailsRef.current}
                            onBeforeGetContent={() => {
                                if (detailsRef.current) {
                                    detailsRef.current.classList.add('print-body');
                                }
                                return Promise.resolve();
                            }}
                            onAfterPrint={() => {
                                if (detailsRef.current) {
                                    detailsRef.current.classList.remove('print-body');
                                }
                            }}
                        />
                    </div>
                )}
                {content}
            </div>
        </ViewLayout>
    );
}
