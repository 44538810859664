import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageableResponse, Submission, SearchableSubmission } from '../../types';
import { message } from 'antd';
import { CSVDownloader, ViewLayout } from '../../components';
import { getOrgAssetCheckouts } from './actions';
import { authContext } from '../../context/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ORG_FEATURES, ROUTES, FORM_TYPES } from '../../constants';
import TableView from '../../components/TableView/TableView';
import { getFormSummaryFields, getViewMenuItems, getSummaryFieldValue, utcStartOfTheDay, utcEndOfTheDay } from '../../utils';

export default function Assets() {
    const [assets, setAssets] = useState<PageableResponse<Submission>>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [selectedFormId, setSelectedFormId] = useState<string | undefined>(undefined);
    const { authState } = useContext(authContext);
    const { user, orgForms } = authState;
    const [filteredAssets, setFilteredAssets] = useState<Submission[]>([]);
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
    const [formStatus, setFormStatus] = useState<string | undefined>(undefined);
    const [searchAssets, setSearchAssets] = useState<SearchableSubmission[]>([]);
    const [startTime, setStartTime] = useState<string | undefined>('');
    const [endTime, setEndTime] = useState<string | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showChart, setShowChart] = useState(true);

    useEffect(() => {
        setSelectedFormId( saved => {
            if (!saved && orgForms) {
                return searchParams.get('formId') ?? orgForms[ORG_FEATURES.ASSETS][0].id;
            }
            return saved;
        })
    }, [orgForms, searchParams]);

    useEffect(() => {
        const formType = orgForms[ORG_FEATURES.ASSETS].filter(item => item.id === selectedFormId)[0]?.type;
        setShowChart(formType && formType === FORM_TYPES.SESSION ? true : false);
    }, [selectedFormId, orgForms]);

    const load = useCallback(async (startTime?: string, endTime?: string, lastEvaluatedKey?: string) => {
        try {
            if (!user || !selectedFormId) {
                return;
            }
            setLoading(true);
            const result = await getOrgAssetCheckouts(user['custom:orgId'], startTime, endTime, selectedFormId, lastEvaluatedKey);
            setAssets(asset => ({ lastEvaluatedKey: result.lastEvaluatedKey, items: asset?.items && lastEvaluatedKey ? [...asset.items, ...result.items] : result.items }));
            const fields = getFormSummaryFields(orgForms, ORG_FEATURES.ASSETS, selectedFormId);
            setSearchAssets(searchAssets => searchAssets.concat(result.items.map((item: Submission) => ({
                searchText: `${fields.map(field => getSummaryFieldValue(field.type, item[field.name as keyof Submission])).join(' ')}`.toLowerCase(),
                item: item
            }))));
        } catch (e) {
            message.error('Error in loading asset checkouts, Please retry again.')
        } finally {
            setLoading(false);
        }
    }, [user, orgForms, selectedFormId]);

    const setDefaultTimeStamps = () => {
        setStartTime(utcStartOfTheDay());
        setEndTime(utcEndOfTheDay());
    }

    useEffect(() => {
        load(startTime, endTime);
    }, [load, startTime, endTime]);

    useEffect(() => {
        setDefaultTimeStamps();
    }, []);

    useEffect(() => {
        if (!assets?.items) {
            return;
        }
        if (searchPhrase) {
            const filteredAssets = searchAssets.filter((asset: SearchableSubmission) => asset.searchText?.includes(searchPhrase.toLowerCase()) && (!formStatus || asset.item.status === formStatus)).map((asset: SearchableSubmission) => asset.item);
            setFilteredAssets(filteredAssets);
        } else {
            const assetList = assets?.items.filter((asset: Submission) => !formStatus || asset.status === formStatus)
            setFilteredAssets(assetList);
        }
    }, [searchPhrase, formStatus, assets, orgForms, selectedFormId, searchAssets]);

    const navigate = useNavigate();

    const onAssetClick = (userId: string, submissionId: string) => navigate(`${ROUTES.ASSETS}/${userId}/${submissionId}`);

    const onFormSelect = (key: string) => {
        setDefaultTimeStamps();
        setSelectedFormId(key);
        setAssets(undefined);
        setSearchAssets([]);
        setSearchParams({ formId: key }, { replace: true });
    };

    const reload = () => {
        setAssets(undefined);
        load(startTime, endTime);
    };

    const onFilterByTime = (dateString: [string, string]) => {
        const [ startTime, endTime ] = dateString;
        setStartTime(startTime);
        setEndTime(endTime);
    }

    return (
        <ViewLayout
            title={`Assets`}
            searchPlaceHolder='Search Asset Checkouts...'
            onSearch={phrase => setSearchPhrase(phrase)}
            exportComponent={
                <CSVDownloader 
                    fileName={`Assets - ${selectedFormId}`}
                    data={filteredAssets}
                    headers={getFormSummaryFields(orgForms, ORG_FEATURES.ASSETS, selectedFormId)}
                    className='refresh-btn full-width border-radius margin-bottom margin-top'/>
            }
            onFilterByTime={dateString  => onFilterByTime(dateString)}
            statusChange={{ show: true, onStatusChange: type => setFormStatus(type!) }}
            dropDown={{
                show: true,
                menuItems: getViewMenuItems(orgForms, ORG_FEATURES.ASSETS).sort((a, b) => a.label.localeCompare(b.label)),
                onChange: onFormSelect,
                selectedItemKey: selectedFormId
            }}
            chart={{ show: showChart ? (formStatus ? true : false) : true, formId: selectedFormId!}}
            onRefreshButtonClick={reload}
        >
            <TableView
                dataSource={filteredAssets}
                rowKey="submissionId"
                isLoading={isLoading}
                summaryColumns={getFormSummaryFields(orgForms, ORG_FEATURES.ASSETS, selectedFormId)}
                onRowClick={(record) => { onAssetClick(record.userId, record.submissionId!) }}
                showStatus={true}
            ></TableView>
        </ViewLayout>
    );
}
