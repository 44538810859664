export const ROUTES = {
    LOGIN: '/login',
    ASSETS: '/assets',
    ACKNOWLEDGEMENTS: '/acknowledgements',
    QR_CODES: '/qr-codes'
}

export const ADMIN_API = 'admin-api';

export const ORG_FEATURES = {
    ACKNOWLEDGEMENT: 'Acknowledgement',
    ASSETS: 'AssetCheckout',
    QR_CODE_GENERATION: 'QrCodeGeneration'

}

export const ORG_FEATURES_ROUTES_MAPPING = {
    [ORG_FEATURES.ACKNOWLEDGEMENT] : ROUTES.ACKNOWLEDGEMENTS,
    [ORG_FEATURES.ASSETS]: ROUTES.ASSETS,
    [ORG_FEATURES.QR_CODE_GENERATION]: ROUTES.QR_CODES
}

export const ASSET_TYPE = {
    ACTIVE: { title: 'Active', status: 'in_progress' },
    HISTORY: { title: 'History', status: 'completed' },
}

export const UI_TOURS = {
    QR_CODES: "QRCodes-Ui-Guide"
}

export const ASSET_CHECKOUT_ACTIONS = {
    COMPLETE: 'setComplete'
}

export const FORM_TYPES ={
    SESSION : 'session'
}

export const AUTHORIZED_ROLES = {
    ALDO_ADMIN: 'AldoAdmin'
}

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
