import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined} from '@ant-design/icons';
import { message, Button } from 'antd';
import { Loading, SubmissionDetailsView, ViewLayout } from '../../components';
import { getAcknowledgementDetails } from './actions';
import { SubmissionDetails } from '../../types';

export default function AcknowledgementDetailsView() {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [details, setDetails] = useState<SubmissionDetails | undefined>();
    const { userId, submissionId } = useParams();
    const detailsRef = useRef<HTMLDivElement>(null);
    

    const loadDetails = useCallback(async () => {
        
        try {
            setLoading(true);
            if (!userId || !submissionId) {
                return;
            }
            const result = await getAcknowledgementDetails(userId, submissionId);
            setDetails(result);
        } catch (e) {
            message.error('Error in loading acknowledgement, Please retry again.')
        } finally {
            setLoading(false);
        }
    }, [userId, submissionId]);

    useEffect(() => {
        loadDetails();
    }, [loadDetails])

    const title = details?.submission?.sessionFormName ?? details?.submission.formTitle ?? '';
    const content = isLoading ? <Loading /> : <SubmissionDetailsView details={details} ref={detailsRef} title={title}/>

    return (
        <ViewLayout
            showSearch={false}
            showTimeFilter={false}
            title={title}
            showBackButton={true}
        >
            <div className='pad-top full-width full-height'>
            {!isLoading && (
                <div className='print-report'>
                    <ReactToPrint
                        trigger={() => <Button type="primary" className='refresh-btn border-radius margin-bottom margin-top' style={{ marginTop: '15px', marginBottom: '20px' }} icon={<PrinterOutlined />}> Print</Button>}
                        content={() => detailsRef.current}
                        onBeforeGetContent={() => {
                            if (detailsRef.current) {
                                detailsRef.current.classList.add('print-body');
                            }
                            return Promise.resolve();
                        }}
                        onAfterPrint={() => {
                            if (detailsRef.current) {
                                detailsRef.current.classList.remove('print-body');
                            }
                        }}
                    />
                </div>
            )}
                {content}
            </div>
        </ViewLayout>
    )
}