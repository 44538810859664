import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageableResponse, Submission, SearchableSubmission } from '../../types';
import { message } from 'antd';
import { CSVDownloader, ViewLayout } from '../../components';
import { getOrgAcknowledgements } from './actions';
import { authContext } from '../../context/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ORG_FEATURES, ROUTES, FORM_TYPES } from '../../constants';
import TableView from '../../components/TableView/TableView';
import { getFormSummaryFields, getViewMenuItems, getSummaryFieldValue, utcStartOfTheDay, utcEndOfTheDay } from '../../utils';

export default function Acknowledgements() {
    const [acknowledgements, setAcknowledgements] = useState<PageableResponse<Submission>>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [selectedFormId, setSelectedFormId] = useState<string | undefined>(undefined);
    const [filteredAcks, setFilteredAcks] = useState<Submission[]>([]);
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
    const [formStatus, setFormStatus] = useState<string | undefined>(undefined);
    const [searchAcks, setSearchAcks] = useState<SearchableSubmission[]>([]);
    const [startTime, setStartTime] = useState<string | undefined>('');
    const [endTime, setEndTime] = useState<string | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const { authState } = useContext(authContext);
    const { user, orgForms } = authState;
    const [showStatus, setShowStatus] = useState(false);

    useEffect(() => {
        setSelectedFormId( saved => {
            if (!saved && orgForms) {
                return searchParams.get('formId') ?? orgForms[ORG_FEATURES.ACKNOWLEDGEMENT][0].id;
            }
            return saved;
        })
    }, [orgForms, searchParams]);

    useEffect(() => {
        const formType = orgForms[ORG_FEATURES.ACKNOWLEDGEMENT].filter(item => item.id === selectedFormId)[0]?.type;
        setShowStatus(formType && formType === FORM_TYPES.SESSION ? true : false);
    }, [selectedFormId, orgForms]);

    const load = useCallback(async (startTime?: string, endTime?: string, lastEvaluatedKey?: string) => {
        try {
            if (!selectedFormId || !user) {
                return;
            }
            setLoading(true);
            const result = await getOrgAcknowledgements(user['custom:orgId'], startTime, endTime, selectedFormId, lastEvaluatedKey);          
            result.items = result.items.sort((a: any, b:any) => {
                const lastValueA = a.submissionId?.split('_').pop();
                const lastValueB = b.submissionId?.split('_').pop();
                return lastValueB?.localeCompare(lastValueA);
            }); 
            setAcknowledgements(ack => ({ lastEvaluatedKey: result.lastEvaluatedKey, items: ack?.items && lastEvaluatedKey ? [...ack.items, ...result.items] : result.items }));
            const fields = getFormSummaryFields(orgForms, ORG_FEATURES.ACKNOWLEDGEMENT, selectedFormId);
            setSearchAcks(searchAcks => searchAcks.concat(result.items.map((item: Submission) => ({
                searchText: `${fields.map(field => getSummaryFieldValue(field.type, item[field.name as keyof Submission])).join(' ')}`.toLowerCase(),
                item: item
            }))));
        } catch (e) {
            message.error('Error in loading acknowledgements, Please retry again.')
        } finally {
            setLoading(false);
        }
    }, [user,orgForms, selectedFormId]);

    const setDefaultTimeStamps = () => {
        setStartTime(utcStartOfTheDay());
        setEndTime(utcEndOfTheDay());
    }

    useEffect(() => {
        load(startTime, endTime);
    }, [load, startTime, endTime]);

    useEffect(() => {
        setDefaultTimeStamps();
    }, []);

    useEffect(() => {
        if (!acknowledgements?.items) {
            return;
        }
        if (searchPhrase) {
            const filteredAcks = searchAcks
                .filter((ack: SearchableSubmission) => ack.searchText?.includes(searchPhrase.toLowerCase()) && (!formStatus || ack.item.status === formStatus))
                .map((ack: SearchableSubmission) => ack.item);
            setFilteredAcks(filteredAcks);
        } else {
            const acks = acknowledgements?.items.filter((ack: Submission) => !formStatus || ack.status === formStatus);
            setFilteredAcks(acks);
        }
    }, [searchPhrase, formStatus, acknowledgements, orgForms, selectedFormId, searchAcks]);

    const navigate = useNavigate();

    const onAckClick = (userId: string, submissionId: string) => navigate(`${ROUTES.ACKNOWLEDGEMENTS}/${userId}/${submissionId}`);

    const onFormSelect = (key: string) => {
        setDefaultTimeStamps();
        setSelectedFormId(key);
        setAcknowledgements(undefined);
        setSearchAcks([]);
        setSearchParams({ formId: key }, { replace: true });
    };

    const reload = () => {
        setAcknowledgements(undefined);
        load(startTime, endTime);
    };

    const onFilterByTime = (dateString: [string, string]) => {
        const [ startTime, endTime ] = dateString;
        setStartTime(startTime);
        setEndTime(endTime);
    }

    return (
        <ViewLayout
            title='Acknowledgements'
            searchPlaceHolder='Search Acknowledgements...'
            onSearch={phrase => setSearchPhrase(phrase)}
            exportComponent={
                <CSVDownloader 
                    fileName={`Acknowledgements - ${selectedFormId}`}
                    data={filteredAcks} 
                    headers={getFormSummaryFields(orgForms, ORG_FEATURES.ACKNOWLEDGEMENT, selectedFormId)}
                    className='refresh-btn full-width border-radius margin-bottom margin-top'/>
            }
            onFilterByTime={dateString  => onFilterByTime(dateString)}
            statusChange={{ show: showStatus, onStatusChange: type => setFormStatus(type!) }}
            dropDown={{
                show: true,
                menuItems: getViewMenuItems(orgForms, ORG_FEATURES.ACKNOWLEDGEMENT).sort((a, b) => a.label.localeCompare(b.label)),
                onChange: onFormSelect,
                selectedItemKey: selectedFormId
            }}
            chart={{ show: showStatus ? (formStatus ? true : false) : true, formId: selectedFormId! }}
            onRefreshButtonClick={reload}
        >
            <TableView
                dataSource={filteredAcks}
                rowKey="submissionId"
                isLoading={isLoading}
                summaryColumns={getFormSummaryFields(orgForms, ORG_FEATURES.ACKNOWLEDGEMENT, selectedFormId)}
                onRowClick={(record) => { onAckClick(record.userId, record.submissionId!) }}
                showStatus={showStatus}
            ></TableView>
        </ViewLayout>
    );
}
