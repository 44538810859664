import { message, Modal, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { setComplete } from './actions';
import { useState } from 'react';

const { confirm } = Modal;

type PropsType = {
    userId: string;
    submissionId: string;
    onAssetReturn: () => Promise<void>;
}

const showConfirm = (title: string, content: string, okFunction: Function) => {
    confirm({
        title: title,
        icon: <ExclamationCircleFilled />,
        content: content,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
            okFunction();
        },
    });
};

export function ReturnAsset({ userId, submissionId, onAssetReturn }: PropsType) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(false);

    const SET_FORM_COMPLETE = {
        confirmatioPopUp: {
            title: "Confirm Asset Return",
            content: "Are you sure you want to confirm the return of the asset? This action cannot be undone."
        },
        navigateTo: '/assets/active'
    };

    const onReturn = async () => {
        try {
            message.loading('Asset is returning ...');
            setLoading(true);
            await setComplete(userId, submissionId);
            await onAssetReturn();
            message.destroy();
            message.success('Asset returned successfully.');
            navigate(SET_FORM_COMPLETE.navigateTo);
        } catch (e) {
            message.destroy();
            message.error('Error in completing asset checkout.');
        } finally {
            setLoading(false);
        }
    };

    const onReturnClick = () => {
        showConfirm(SET_FORM_COMPLETE.confirmatioPopUp.title, SET_FORM_COMPLETE.confirmatioPopUp.content, onReturn)
    }

    return (
        <Button style={{'height': '42px'}} className='margin-top border-radius' type='primary' disabled={isLoading} onClick={onReturnClick}>Confirm asset return</Button>
    );
};
