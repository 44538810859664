import { Avatar, Space, Typography, Flex } from "antd";

type PropsType = {
    size?: number
}

export default function AppLogo({ size = 108}: PropsType) {
    return (
        <Space direction="vertical" className="app-logo content-centered">
            <Avatar src="/aldo-logo.png" size={size} shape='square' />
            <Typography.Title level={4} className='app-name content-centered'>ALDO Administrator
                <Flex className="margin-top">
                    <Typography className="app-name">powered by</Typography>
                    <img className=" margin-left" height={16} src="/epar.png" alt="" />
                </Flex>
            </Typography.Title>
        </Space>
    );
}