import React, { forwardRef } from 'react';
import { Field, RadioValue, SubmissionDetails } from '../../types';
import { Button, Checkbox, DescriptionsProps, Empty, Image, Space, Switch, Flex } from 'antd';
import { getFileFriendlyName, isImage, toTimeString, toDateTimeString } from '../../utils';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadFile } from './actions';

type PropsType = {
    details?: SubmissionDetails,
    actions?: React.ReactNode[],
    title?: string
};

const DetailsPane = forwardRef<HTMLDivElement, PropsType>((props, ref) => {
    const { details, actions, title } = props;
  

    const getImagePreview = (urls: any) => {
        if (!urls) return <></>;
        return (
            <div className="pad-vertical">
                <Image.PreviewGroup>
                    {(urls as string[]).map((url, idx) => (
                        <Image height="80px" key={`img-${idx}`} src={url} />
                    ))}
                </Image.PreviewGroup>
            </div>
        );
    };

    const getPhoneNumberPreview = (values: any) => {
        if (!values) return <></>;
        return <div>{values.dialCode} {values.number}</div>;
    };

    const getDatePreview = (values: any) => {
        if (!values) return <></>;
        return <div>{toDateTimeString(values)}</div>;
    };

    const getTimePreview = (values: any) => {
        if (!values) return <></>;
        return <div>{toTimeString(values)}</div>;
    };

    const getCheckBoxPreview = (values: any) => {
        if (!values) return <></>;
        return <div><Checkbox disabled checked={values} /></div>;
    };

    const getUploadPreview = (urls: any) => {
        if (!urls) return <></>;
        const urlss = urls as string[];
        const images: string[] = [];
        const files: string[] = [];
        urlss.forEach(url => {
            if (isImage(url)) {
                images.push(url);
            } else {
                files.push(url);
            }
        });
        return (
            <div className="pad-vertical">
                <Space direction="vertical">
                    {getImagePreview(images)}
                    {files.map((url, idx) => {
                        const fileName = getFileFriendlyName(url) ?? '';
                        return (
                            <Button type="link" key={`attc-${idx}`} className="border-radius" onClick={() => downloadFile(url, fileName)} icon={<DownloadOutlined />}>
                                {fileName}
                            </Button>
                        );
                    })}
                </Space>
            </div>
        );
    };

    const getSwitchPreview = (values: any) => {
        if (!values) return <></>;
        return <div><Switch size="small" disabled checked={values} /></div>;
    };

    const getRadioPreview = (possibleValues: RadioValue[], values: any) => {
        if (!values) return <></>;
        return possibleValues.find(val => val.value === values)?.label ?? '';
    };

    const getChildComponent = (field: Field, value: any) => {
        switch (field.type) {
            case 'phone':
                return getPhoneNumberPreview(value);
            case 'signature':
            case 'camera':
                return getImagePreview(value);
            case 'date':
                return getDatePreview(value);
            case 'time':
                return getTimePreview(value);
            case 'checkbox':
                return getCheckBoxPreview(value);
            case 'switch':
                return getSwitchPreview(value);
            case 'upload':
                return getUploadPreview(value);
            case 'radio':
            case 'radio.button':
                return getRadioPreview(field.radioValues ?? [], value);
            case 'divider':
            case 'content':
            case 'title':
                return null;
            default:
                return value;
        }
    };

    const getItems = (): DescriptionsProps['items'] => {
        const items = details?.schema.fields.map((field => {
            const children = getChildComponent(field, details.submission.formValues[field.name]);
            const label = <div dangerouslySetInnerHTML={{ __html: field.label }}></div>;
            return children || field.type === 'divider' ? {
                key: field.name,
                label: label,
                children: children
            } : null;
        })) ?? [];
        
        return items.filter(item => !!item) as DescriptionsProps['items'];
    };

    if (!details) {
        return (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />);
    }
    
    return (
        <div ref={ref}>
            <div className="print-only-header report-header">{title}</div>
            {getItems()?.map(item => (
                <div key={item.key} className={`detail-row${String(item.label).length > 30 ? '-long' : ''}`}>
                    <div className="detail-col detail-col__label">{item.label}</div>
                    <span className="divider"></span>
                    <div className="detail-col detail-col__value">{item.children}</div>
                </div>
            ))}
            <Flex className="action-bar">
                {actions?.map((item, i) => (
                    <div key={`act-${i}`}>{item}</div>
                ))}
            </Flex>
        </div>
    );
});

export default DetailsPane;
