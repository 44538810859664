import { PageableResponse, Submission, SubmissionDetails, ActionResponse } from "../../types";
import { getApi, putApi } from "../../utils";
import { ASSET_CHECKOUT_ACTIONS } from "../../constants";

export const getOrgAssetCheckouts = async (orgId: string, startTime?: string, endTime?: string, formUrl?:string, lastEvaluatedKey?: string): Promise<PageableResponse<Submission>> => {
    return getApi<PageableResponse<Submission>>(`/org-submissions/${orgId}?formId=${formUrl}${lastEvaluatedKey ? '&exclusiveStartKey='.concat(lastEvaluatedKey) : ''}${startTime ? '&startTime='.concat(startTime) : ''}${endTime ? '&endTime='.concat(endTime) : ''}`);
}

export const getAssetDetails = async (userId: string, submissionId: string): Promise<SubmissionDetails> => {
    return getApi<SubmissionDetails>(`/submission/${userId}/${submissionId}`);
}

export const setComplete = async (userId: string, submissionId: string): Promise<ActionResponse> => {
    return await putApi<ActionResponse>('/update-submission/AssetCheckout', { userId, submissionId, action: ASSET_CHECKOUT_ACTIONS.COMPLETE });
}