import { Dispatch, ReactElement, ReactNode, Reducer, createContext, useReducer } from "react";
import { OrgSchemas } from "../types";

const ACTIONS = {
    USER_SIGNED_IN: 'user-signed-in',
    ORG_FEATURES_LOADED: 'org-features-loaded',
    ORG_FORMS_LOADED: 'org-forms-loaded',
    USER_SIGNING_IN: 'user-signing-in',
    USER_SIGN_IN_ERROR: 'user-sign-in-error'
};

type StateType = {
    user: any,
    orgFeatures: string[],
    orgForms: OrgSchemas,
    userSigningIn: boolean,
    userSignInError: string | null
};

export type ActionType = {
    type: string;
    payload: any;
};

export type DispatchType = Dispatch<ActionType>;


type ReduceType = Reducer<StateType, ActionType>;

type ContextType = {
    authState: StateType,
    dispatch: DispatchType
};

type PropsType = {
    children: ReactNode
};

const initialState: StateType = {
    user: null,
    orgFeatures: [],
    orgForms: {},
    userSigningIn: false,
    userSignInError: null
};

const authContext = createContext<ContextType>({ authState: initialState, dispatch: () => null });
const { Provider } = authContext;

const AuthProvider = ({ children }: PropsType): ReactElement => {
    const [authState, dispatch] = useReducer<ReduceType>((state: StateType, action: ActionType): StateType => {
        const { type, payload } = action;
        switch (type) {
            case ACTIONS.USER_SIGNED_IN:
                return { ...state, 'user': payload };
            case ACTIONS.ORG_FEATURES_LOADED:
                return { ...state, orgFeatures: payload };
            case ACTIONS.ORG_FORMS_LOADED:
                return { ...state, orgForms: payload };
            case ACTIONS.USER_SIGNING_IN:
                return { ...state, userSigningIn: payload };
            case ACTIONS.USER_SIGN_IN_ERROR:
                return { ...state, userSignInError: payload };
            default:
                return state;
        }
    }, initialState);


    return <Provider value={{ authState, dispatch }}>{children}</Provider>;
};

export { authContext, AuthProvider, ACTIONS };
