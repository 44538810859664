import { Layout } from 'antd';
import React, { useContext } from 'react';
import Slider from './Slider';
import { Routes } from 'react-router-dom';
import { authContext } from '../../context/auth';

import { getOrgRouteViews } from './routes';


export default function Main() {

    const { authState } = useContext(authContext);
    const { orgFeatures } = authState;

    return (
        <Layout className='full-height'>
            <Slider />
            <Layout>
                <Layout.Content className='pad-all'>
                    <Routes>
                        {getOrgRouteViews(orgFeatures)}
                    </Routes>
                </Layout.Content>
            </Layout>
        </Layout>
    );
}