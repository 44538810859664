import { Button, Form, Input, Layout, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppLogo } from '../../components';
import { authContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../utils';
import { CloseCircleOutlined } from '@ant-design/icons';

type LoginFormType = {
    username: string,
    password: string,
    newPassword?: string,
    confirmNewPassword?: string
}

export default function Main() {
    const [requireNewPassword, setRequireNewPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { dispatch, authState } = useContext(authContext);
    const { userSigningIn, userSignInError } = authState;

    const [form] = Form.useForm<LoginFormType>();

    const navigate = useNavigate();

    useEffect(() => {
        if (userSignInError) {
            setErrorMessage(userSignInError);
        }
    }, [userSignInError]);

    const login = async ({ newPassword, confirmNewPassword, username, password }: LoginFormType) => {
        try {
            setErrorMessage(null);
            if (requireNewPassword && newPassword !== confirmNewPassword) {
                setErrorMessage("New password does not match with confirm password");
                return;
            }

            const error = await signIn(username, password, dispatch, newPassword);
            if (error && error === "NEW_PASSWORD_REQUIRED") {
                setRequireNewPassword(true);
                setErrorMessage("Please set a new password");
                return;
            }

            if (error) {
                setErrorMessage(error);
                return;
            }

            if (userSignInError) {
                setErrorMessage(userSignInError);
                return;
            }

            navigate('/');
        } catch (e) {
            console.log(e);
        }
    };

    const newPasswordFieldItems = (requireNewPassword) ? <>
        <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
                {
                    required: true,
                    message: 'Please input your new password',
                },
            ]}
        >
            <Input.Password placeholder="Type you new password here" disabled={userSigningIn} />
        </Form.Item>
        <Form.Item
            label="Confirm New Password"
            name="confirmNewPassword"
            rules={[
                {
                    required: true,
                    message: 'Please re-enter your new password',
                },
            ]}
        >
            <Input.Password placeholder="Type you new password here too" disabled={userSigningIn} />
        </Form.Item>
    </> : <></>;

    const errorView = errorMessage && !userSigningIn ? <Typography.Paragraph className='login-error pad-all'>
        <CloseCircleOutlined style={{ color: 'red' }} /> {errorMessage}
    </Typography.Paragraph> : <></>;

    return (
        <Layout className='theme-min-height full-width'>
            <Layout.Content className='content-centered'>
                <Form
                    layout='vertical'
                    className='login-form box-style'
                    form={form}
                    onFinish={login}
                >
                    <AppLogo size={72} />
                    {errorView}
                    <Form.Item
                        label="Username"
                        name="username"
                        required={true}
                        rules={[{
                            required: true,
                            message: "Please enter your username."
                        }]}
                    >
                        <Input placeholder='Enter your username' disabled={userSigningIn} />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        required={true}
                        rules={[{
                            required: true,
                            message: "Please enter your password."
                        }]}
                    >
                        <Input.Password placeholder='Enter your password' disabled={userSigningIn} />
                    </Form.Item>
                    {newPasswordFieldItems}
                    <Button type='primary' className='full-width' htmlType="submit" loading={userSigningIn}>
                        Log in
                    </Button>
                </Form>
            </Layout.Content>
        </Layout >
    )
}