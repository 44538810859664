import React, { useEffect, useState } from 'react';
import { Typography, Table } from 'antd';
import { Loading } from '../../components';
import { Submission, SummaryField } from '../../types';
import { getSummaryFieldValue } from '../../utils';

type PropsType = {
    dataSource: Submission[],
    rowKey: string
    isLoading?: boolean,
    summaryColumns?: SummaryField[],
    onRowClick?: (record: any) => void | undefined,
    scrollViewHeight?: number,
    showStatus?: boolean
}

const COLUMN_HEIGHT = 43;
const MINIMUM_COLUMN_COUNT = 20;

export default function TableView({ dataSource, rowKey, isLoading = false, summaryColumns, onRowClick, scrollViewHeight, showStatus = false}: PropsType) { 

    const [maxHeight, setMaxHeight] = useState<number>(0);
    const [numberOfColumns, setNumberOfColumns] = useState<number>(MINIMUM_COLUMN_COUNT);

    useEffect(() => {
        setMaxHeight(scrollViewHeight ? scrollViewHeight - 100 : 0);
    }, [scrollViewHeight]);

    useEffect(() => {
        setNumberOfColumns(Math.max( Math.floor(maxHeight / COLUMN_HEIGHT), MINIMUM_COLUMN_COUNT ))
    }, [maxHeight]);

    const getColumns = () => {
        const statusColumn = {
            width:10,
            dataIndex: 'status',
            key: 'status',
            title: '',
            render: (value:string) => {             
                return {                   
                  props: {
                    className: `status-column status-column-${value}`,
                    children:value,
                  },                  
                };
              },
          };

          return [
            ...(showStatus ? [statusColumn] : []),
            ...summaryColumns!.map(({ type, name, label }) => ({
              dataIndex: name,
              key: name,
              title: label,
              render: (value: any) => <Typography.Text>{getSummaryFieldValue(type, value)}</Typography.Text>,
            })),
          ];
      };  
    return (
        <Table
            className='table-view'
            dataSource={dataSource}
            columns={getColumns()}
            rowKey={rowKey}
            size="small"
            loading={{
                spinning: isLoading,
                indicator: <Loading />
            }}
            onRow={(record) => {
                return {
                    onClick: () => { onRowClick!(record) },
                };
            }}
            pagination={{pageSize: numberOfColumns, total: dataSource.length, defaultCurrent: 1, showSizeChanger: false}}
            scroll={{ y: maxHeight }}
            bordered
        >
        </Table>
    );

}