import React, { useCallback, useEffect, useState } from 'react';
import { KeyValues, OrgSchemas } from '../../../types';
import { Button, Flex, QRCode, Space, message, Typography } from 'antd';
import { DownloadOutlined, LeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { ALDO_PWA_APP_URL } from '../../../config/config';
import { download, print } from '../../../utils/file-operations';

type PropsType = {
    values: KeyValues,
    orgId: string,
    schema: OrgSchemas,
    onBackClick?: () => void
}

export default function QrView({ values, orgId, schema, onBackClick }: PropsType) {
    const [qrData, setQrData] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(true);

    const getSubmissionTypeForms = (formType: string) => schema[formType] ?? [];
    const formOptions = getSubmissionTypeForms(values.submissionType).find(form => form.id === values.formId);

    const loadQrData = useCallback(() => {
        try {
            setLoading(true);
            const data = { ...values, orgId };
            const url = `${ALDO_PWA_APP_URL}/artifact?qr-data=${btoa(encodeURIComponent(JSON.stringify(data)))}`;
            setQrData(url);
        } catch (e) {
            message.error('Error in creating QR code.')
        } finally {
            setLoading(false);
        }
    }, [values, orgId]);

    useEffect(() => {
        loadQrData();
    }, [loadQrData]);

    /**
     * Get top margin of the canvas title by number of lines of text
     * @param {number} line 
     * @returns 
     */
    const getTitleTop = (line: number) => {
        switch(line) {
            case 1: 
                return 45;
            case 2: 
                return 30;
            case 3: 
                return 20;
            default: 
                return 10;            
        }
    }

    /**
     * Dive canvas title into multi lines
     * @param {CanvasRenderingContext2D} context 
     * @param {string} text 
     * @param {number} x 
     * @param {number}maxWidth 
     * @param {number} lineHeight 
     * @returns 
     */
    const wrapText = (context: CanvasRenderingContext2D, text: string, x: number, maxWidth: number, lineHeight: number) => {
        const words = text.split(' ');
        const numberOfLines = Math.floor(text.length / 35) + 1;
        let titleTop = getTitleTop(numberOfLines);
        let line = '';

        for(let n = 0; n < words.length; n++) {
          const testLine = line + words[n] + ' ';
          const metrics = context.measureText(testLine);
          const testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, titleTop);
            line = words[n] + ' ';
            titleTop += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, titleTop);
        return context;
    }

    const getQrCodeDataUrl = (formName: string, canvasId: string): string | null => {
        const QrCodeCanvas = document.getElementById(canvasId)?.querySelector<HTMLCanvasElement>('canvas')
        const pd = 20
        const aparLogoWidth = 160
        const aparLogoHeight = 30
        const fontSize = 20
        const titleTop = 50
        const lineHeight = 22

        if (QrCodeCanvas) {
            const paddedCanvas = document.createElement('canvas')
            paddedCanvas.width = QrCodeCanvas.width + 2 * pd
            paddedCanvas.height = QrCodeCanvas.height + 9 * pd
            let context = paddedCanvas.getContext('2d')
            if (context) {

                context.fillStyle = 'white'
                context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height)
                context.drawImage(QrCodeCanvas, pd, pd + titleTop + fontSize)

                context.fillStyle = 'black'
                context.font = `bold ${fontSize}px Arial`
                context.textAlign = 'center'
                context.textBaseline = 'middle'
                context = wrapText(context, formName, paddedCanvas.width / 2, paddedCanvas.width, lineHeight)

                const epar = new Image()
                epar.src = '/poweredByEpar.png'
                if (epar.complete) context.drawImage(epar, (paddedCanvas.width - aparLogoWidth) / 2, paddedCanvas.height - aparLogoHeight - pd, aparLogoWidth, aparLogoHeight);

                return paddedCanvas.toDataURL('image/png')
            }
            return QrCodeCanvas.toDataURL('image/png')
        }
        return null
    }

    const getTitle = () => values.formName ?? values.sessionFormName ?? formOptions?.name ?? values.submissionType;

    const downloadQRCode = () => {
        const dataUrl = getQrCodeDataUrl(getTitle(), `qr-code_${values.formId}`);
        if (dataUrl) {
            download(dataUrl, `${formOptions?.name ?? values.formName} - QR-Code.png`);
        }
    };

    const printQRCode = () => {
        const dataUrl = getQrCodeDataUrl(getTitle(), `qr-code_${values.formId}`);
        if (dataUrl) {
            print(dataUrl, 'image', `${formOptions?.name ?? values.formName} - QR-Code`);
        }
    };

    return (

        <div className='full-width full-height pad-hori'>
            <Space id={`qr-code_${values.formId}`} direction='vertical' align='center' className='full-width'>
                <Flex vertical align='center' justify='center' className='margin-bottom'>
                    <Typography.Title level={5} className='margin-top'>{getTitle()}</Typography.Title>
                    <QRCode bgColor='white' icon='/aldo-logo.png' iconSize={100} bordered={false} size={350} value={qrData} status={isLoading ? 'loading' : 'active'} />
                    <Flex className='qr-footer full-width' gap="middle" justify='center'>
                        <img className='logo' src="/poweredByEpar.png" alt="epar logo" height={20} />
                    </Flex>
                </Flex>
                <Space >
                    {onBackClick && <Button className='border-radius' icon={<LeftOutlined />} onClick={onBackClick}>Back</Button>}
                    <Button className='border-radius' type='primary' icon={<PrinterOutlined />} onClick={printQRCode}>Print</Button>
                    <Button className='border-radius' icon={<DownloadOutlined />} onClick={downloadQRCode}>Download</Button>
                </Space>
            </Space>
        </div>
    )
}