import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Layout, Space, Menu, MenuProps, Flex } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../utils';
import { authContext } from '../../context/auth';
import { findMatchingRoute, getOrgRouteMenuItems } from './routes';

type SliderItemProps = { collapsed: boolean };
type MenuItem = Required<MenuProps>['items'][number];

function SliderAppLogo({ collapsed }: SliderItemProps) {
    return collapsed ?
        <Space className='pad-all content-centered'>
            <Avatar src='/aldo-logo-white.png' size={52} shape='square' style={{color:'white'}}/>
        </Space> :
        <Space className='pad-all slider-exp-app-logo'>
            <img className="admin-logo" src="/admin-logo.png" alt="" />
        </Space>

}

const getMenuItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], danger: boolean = false): MenuItem => {
    return { key, icon, children, label, danger } as MenuItem;
}

function SliderMenus() {
    const { authState } = useContext(authContext);
    const { orgFeatures } = authState;

    const [selectedMenu, setSelectedMenu] = useState<string>('');
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname !== selectedMenu) {
            setSelectedMenu(findMatchingRoute(orgFeatures ?? [], pathname) ?? '');
        }
    }, [pathname, selectedMenu, orgFeatures]);

    const onItemSelect = ({ key }: { key: string }) => {
        navigate(key);
    }

    return (
        <Menu
            theme='dark'
            selectedKeys={[selectedMenu]}
            mode='inline'
            items={getOrgRouteMenuItems(orgFeatures)}
            className='slider-menu'
            onSelect={onItemSelect} />
    );
}

function SliderLogOut() {
    const { dispatch, authState } = useContext(authContext);
    const { user } = authState;

    const items = [
        getMenuItem(`${user.name} ${user.family_name ?? ''}`, 'profile', <UserOutlined />),
        getMenuItem('Log out', 'logout', <LogoutOutlined />, undefined, true)
    ];


    const onSelect = ({ key }: { key: string }) => {
        if (key === 'logout') {
            logout(dispatch);
        }
    }

    return (
        <Menu
            theme='dark'
            mode='inline'
            selectedKeys={['']}
            items={items}
            className='slider-menu'
            onSelect={onSelect}
        />
    );
}

export default function Slider() {
    const [collapsed, setCollapsed] = useState<boolean>(false);

    return (
        <Layout.Sider
            width={250}
            collapsible={true}
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            className='main-slider full-height'>
            <Flex justify='space-between' className='full-height' vertical>
                <Space size={60} direction='vertical'>
                    <SliderAppLogo collapsed={collapsed} />
                    <SliderMenus />
                </Space>
                <SliderLogOut />
            </Flex>
        </Layout.Sider>
    );
}