import { API, Auth } from "aws-amplify"
import { ADMIN_API } from "../constants";
import { KeyValues } from "../types";

const getSessionToken = async (): Promise<string> => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}

export const getApi = async <T>(path: string, params: KeyValues = {}): Promise<T> => {
    const token = await getSessionToken();
    return API.get(ADMIN_API, path, {
        ...params,
        headers: {
            Authorization: token
        }
    });
}

export const putApi = async <T>(path: string, body: KeyValues={}): Promise<T> => {
    const token = await getSessionToken();
    return API.put(ADMIN_API, path, {
        headers: {
            Authorization: token,
        },
        body
    });
}