import React, { useContext, useEffect, useState } from 'react';
import { Loading, ViewLayout } from '../../components';
import { OrgSchemas, OrgSchema } from '../../types';
import { authContext } from '../../context/auth';
import { getOrgSchemas } from './actions';
import { message, List, Avatar } from 'antd';
import { QrCodeModal } from './components';



export default function QrCodes() {
    const [schema, setSchema] = useState<OrgSchemas>({});
    const [isLoading, setLoading] = useState<boolean>(true);
    const { authState } = useContext(authContext);
    const { user } = authState;
    const [isModelOpen, setModalOpen] = useState(false);
    const [currentForm, setCurrentForm] = useState<OrgSchema>();
    const [formList, setForms] = useState<OrgSchema[]>([]);
    const [filteredForms, setFilterForms] = useState<OrgSchema[]>([]);
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!user) return;
        setLoading(true);
        getOrgSchemas(user['custom:orgId'])
            .then((formSchema) => {
                setSchema(formSchema);
                const forms = Object.keys(formSchema).flatMap(submissionType => {
                    return formSchema[submissionType].sort((a, b) => a.name!.localeCompare(b.name!)).map((form: any) => ({
                        searchtext: `${submissionType.concat(...form.name)}`,
                        submissionType,
                        ...form
                    }));
                });
                setForms(forms)
            })
            .catch(e => message.error('Error in getting schema. Please retry.'))
            .finally(() => setLoading(false));
    }, [user]);

    useEffect(() => {
        if (searchPhrase) {
            const filteredArray = formList.filter(form => form.searchtext?.toLowerCase().includes(searchPhrase.toLowerCase()));
            setFilterForms(filteredArray);
        } else {
            setFilterForms(formList);
        }
    }, [searchPhrase, formList]);

    const openQRModal = (form: OrgSchema) => {
        setCurrentForm(form);
        setModalOpen(true);
    };

    const onCloseModal = () => {
        setModalOpen(false);
        setCurrentForm(undefined);
    };

    const content = isLoading ? <Loading /> : <List
        className="form-list"
        dataSource={filteredForms}
        renderItem={(form) => (
            <List.Item className="form-list-item" onClick={() => openQRModal(form)}>
                <List.Item.Meta
                    avatar={<Avatar shape="square" src={`/${form.submissionType}.png`} />}
                    title={form.name}
                    description={form.submissionType}
                />
            </List.Item>
        )}
    />;

    const qrModalView = isModelOpen && currentForm ? <QrCodeModal
        isOpen={isModelOpen}
        form={currentForm}
        onClose={onCloseModal}
        schema={schema}
        orgId={user?.['custom:orgId'] as string ?? ''}
    /> : <></>;

    return (
        <>
            {qrModalView}
            <ViewLayout
                title='QR Codes'
                onSearch={phrase => setSearchPhrase(phrase)}
                searchPlaceHolder='Search forms'
                showTimeFilter={false}
            >
                <div className='pad-top full-width full-height'>
                    {content}
                </div>
            </ViewLayout>
        </>);
}