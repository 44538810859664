export const AWS_CONFIG = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_hulGkZArz',
        userPoolWebClientId: '3oqa0mfsauskombgnjlrn4j2cn',
    }
};

export const CONFIG = {
    baseApiUrl: 'https://api.aldoforms.com/admin-api'
};

export const ALDO_PWA_APP_URL = 'https://aldoforms.com';