import printJS, { PrintTypes } from 'print-js';

export const download = (url: string, fileName: string) => {
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

export const print = (url: string, fileType: PrintTypes, fileName: string) => {
    printJS({
        printable: [url],
        type: fileType,
        documentTitle: fileName,
        style: '@page { size: A4; margin: 10mm; } body { display: flex; align-items: center; justify-content: center; margin-top:50mm}',
    });
};
