import { Amplify } from "aws-amplify";
import { CONFIG } from "../config/config";
import { ADMIN_API } from "../constants";

export function awsAmplifyApiInit() {
    const { baseApiUrl } = CONFIG;

    Amplify.configure({
        API: {
            endpoints: [
                {
                    name: ADMIN_API,
                    endpoint: `${baseApiUrl}`
                }
            ]
        }
    });
}