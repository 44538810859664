
import { Divider, Input, Space, Dropdown, Button, Row, Col, Radio, DatePicker } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { DownOutlined, LeftOutlined, RedoOutlined } from '@ant-design/icons';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { dayJs, endOfTheDay, startOfTheDay, utcStartOfTheDay, utcEndOfTheDay, toDateTimeISOString } from '../../utils';
import ScrollView from '../ScrollView/ScrollView';
import { DropDownMenu } from '../../types';
import { useNavigate } from 'react-router-dom';
import { HourlyChart } from '..';
import { DEFAULT_DATE_FORMAT } from '../../constants';

type DropdownType = {
    show: boolean,
    menuItems: DropDownMenu[],
    selectedItemKey?: string,
    onChange: (key: string) => void
}

type ChartType = {
    show: boolean,
    formId: string,
    status?: string,
    onRefresh?: boolean,
}

type StatusChangeType = {
    show: boolean,
    onStatusChange?: (text: string) => void
}

type PropsType = {
    title: string,
    children: ReactElement,
    exportComponent?: JSX.Element,
    searchPlaceHolder?: string,
    showSearch?: boolean,
    showTimeFilter?: boolean,
    onSearch?: (text: string) => void | undefined,
    onFilterByTime?: (dateString: [string, string]) => void | undefined,
    dropDown?: DropdownType,
    onRefreshButtonClick?: () => Promise<void> | void,
    showBackButton?: boolean,
    chart?: ChartType,
    statusChange?: StatusChangeType
}

type TimeFilterValueType = {
    startTime?: string,
    endTime?: string
}

const DEFAULT_HEIGHT = 200;

const { RangePicker } = DatePicker;

export default function ViewLayout({ title, children, exportComponent, searchPlaceHolder, showSearch = true, showTimeFilter = true, onSearch, onFilterByTime, dropDown, onRefreshButtonClick, showBackButton = false, chart, statusChange }: PropsType) {
    const navigate = useNavigate();
    const [isRefreshChart, setRefreshChart] = useState(false);
    const [scrollViewHeight, setScrollViewHeight] = useState<number>(DEFAULT_HEIGHT);
    const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
    const [timeFilterValues, setTimeFilterValues] = useState<TimeFilterValueType | undefined>(undefined);
    const scrollViewRef = useRef<HTMLDivElement>(null);

    const calculateScrollViewHeight = useCallback(() => {
        if (scrollViewRef.current) {
            setScrollViewHeight(scrollViewRef.current.clientHeight);
        }
    }, []);

    const setDefaultTimeStamps = () => {
        setTimeFilterValues(() => ({ startTime: startOfTheDay(), endTime: endOfTheDay() }));
    };

    const onTimeFilterChange = (dateString: [string, string]) => {
        const [startTime, endTime] = dateString;
        const rangeStart = startTime ? dayJs(startTime, DEFAULT_DATE_FORMAT).format() : startOfTheDay();
        const rangeEnd = endTime ? dayJs(endTime, DEFAULT_DATE_FORMAT).format() : endOfTheDay();
        setTimeFilterValues({ startTime: rangeStart, endTime: rangeEnd });
        onFilterByTime!([startTime ? toDateTimeISOString(startTime) : utcStartOfTheDay(), endTime ? toDateTimeISOString(endTime) : utcEndOfTheDay()]);
    }

    useEffect(() => {
        const timeout = setTimeout(calculateScrollViewHeight, 500);
        window.addEventListener('resize', calculateScrollViewHeight);
        return () => {
            clearTimeout(timeout);
            window.removeEventListener('resize', calculateScrollViewHeight);
        }
    }, [calculateScrollViewHeight]);

    useEffect(() => {
        setDefaultTimeStamps();
    }, []);

    const renderChildren = useCallback(() => {
        return React.cloneElement(children, {
            scrollViewHeight: scrollViewHeight,
        });
    }, [children, scrollViewHeight]);

    const onChartCollapse = () => {
        setTimeout(() => calculateScrollViewHeight(), 300);
    }

    const getSelectedItemLabel = () => {
        const selectedItem = dropDown?.menuItems.find(item => item.key === dropDown?.selectedItemKey);
        return selectedItem?.label ?? 'Select a form';
    }

    const onDropDownClick = (e: any) => {
        dropDown?.onChange(e.key);
        setDefaultTimeStamps();
        handleStatusChange(null);
    };

    const dropDownElement = (dropDown?.show && dropDown.menuItems) ?
        (<Col lg={statusChange?.show ? 12 : 15} xs={24}>
            <Dropdown
                className='margin-top margin-bottom border-radius full-width dropdown-element'
                menu={{
                    items: dropDown?.menuItems,
                    onClick: onDropDownClick
                }}
            >
                <Button className='drop-down-button'>
                    {getSelectedItemLabel()}
                    <DownOutlined />
                </Button>
            </Dropdown>
        </Col>) : <></>;


    const searchElement = showSearch ? (
        <Col span={12}>
            <Input.Search className='margin-bottom margin-top full-width' onChange={(e) => onSearch!(e.target.value)} placeholder={searchPlaceHolder} />
        </Col>
    ) : <></>;

    const onRefresh = () => {
        setRefreshChart(true);
        handleStatusChange(null);
        if (onRefreshButtonClick) onRefreshButtonClick();
        setTimeout(() =>
            setRefreshChart(false)
            , 100);
    };

    const handleStatusChange = (e: RadioChangeEvent | null) => {
        if (!e) {
            setSelectedType(undefined);
            statusChange?.onStatusChange!('');
        } else {
            setSelectedType(e.target.value);
            statusChange?.onStatusChange!(e.target.value);
        }
    };

    const exportElement = (
        <Col span={6}>
            {exportComponent}
        </Col>
    );



    const refreshElement = onRefreshButtonClick ? (
        <Col span={6}>
            <Button className='refresh-btn full-width border-radius margin-bottom margin-top' icon={<RedoOutlined />} onClick={onRefresh} >
                Refresh
            </Button>
        </Col>
    ) : <></>;

    const statusSelectElement = statusChange?.show ? (
        <Col lg={5} xs={24} className='status-element'>
            <Row justify='end' className='full-width' >
                <Col lg={24} xs={8}>
                    <Radio.Group className=' margin-bottom margin-top status-btn full-width' value={selectedType} onChange={handleStatusChange} >
                        <Radio.Button className='full-width' type='primary' value="in_progress">Active</Radio.Button>
                        <Radio.Button className='full-width' value="completed">Completed</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        </Col>
    ) : <></>;

    const dateRangeElement = () => {
        if (!showTimeFilter) {
            return <></>
        }
        const rangeStart = dayJs(timeFilterValues?.startTime, DEFAULT_DATE_FORMAT);
        const rangeEnd = dayJs(timeFilterValues?.endTime, DEFAULT_DATE_FORMAT);
        return (
            <>
                <Col lg={statusChange?.show ? 7 : 9} xs={24}>
                    <RangePicker
                        className='margin-top margin-bottom border-radius full-width date-range-element'
                        showTime={{
                            format: 'HH:mm',
                            minuteStep: 5
                        }}
                        format="YYYY-MM-DD HH:mm"
                        value={[rangeStart, rangeEnd]}
                        onChange={(value, dateString) => onTimeFilterChange(dateString)}
                    />
                </Col>
            </>
        )
    };

    const headerElement = (
        <Row gutter={8}>
            {dropDownElement}
            {dateRangeElement()}
            {statusSelectElement}
        </Row>
    );

    const backButton = showBackButton ? (
        <Button className='border-radius margin-top margin-bottom' icon={<LeftOutlined />} onClick={() => navigate(-1)} />
    ) : <></>;

    const chartElement = chart?.show ? <HourlyChart formId={chart.formId} status={selectedType ?? chart.status} onCollapse={onChartCollapse} onRefresh={isRefreshChart} /> : <></>;

    const divider = title ? <Divider className='no-margin' /> : <></>;

    return (
        <Space.Compact direction='vertical' className='full-width box-style pad-all full-height'>
            <Row gutter={8} className='header' justify='space-between'>
                <Col>
                    <Row>
                        {backButton}
                        <h1 className='header-title margin-top margin-bottom pad-hori'>{title}</h1>
                    </Row>
                </Col>
                <Col lg={12} xs={24}>
                    <Row gutter={8}>
                        {exportElement}
                        {refreshElement}
                        {searchElement}
                    </Row>
                </Col>
            </Row>
            {divider}
            <div className='view-layout-content full-width'>
                <Space.Compact direction='vertical' className='full-width full-height'>
                    {headerElement}
                    {chartElement}
                    <ScrollView elemRef={scrollViewRef}>
                        {renderChildren()}
                    </ScrollView>
                </Space.Compact>
            </div>
        </Space.Compact>
    )
}
