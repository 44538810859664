import { Button, Flex, Form, Input, Typography, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { ExtraField, KeyValues, OrgSchemas } from '../../../types';
import { FORM_TYPES } from '../../../constants';

type PropsType = {
    schema: OrgSchemas,
    values: KeyValues,
    onSubmit: (values: KeyValues) => void,
    onBackClick?: () => void
}

export default function FormView({ schema, values, onSubmit, onBackClick }: PropsType) {
    const formId = values.formId;
    const submissionType= values.submissionType;
    const [form] = Form.useForm();

    const onFormSubmit = (vals: KeyValues) => {
        vals.formId = formId;
        onSubmit(vals);
    };

    const getSubmissionTypeForms = useCallback(() => schema[submissionType] ?? [], [schema, submissionType]);

    const getFormExtraFields = (): ExtraField[] => {
        let frm ;
        if(values.type === FORM_TYPES.SESSION){
            frm = getSubmissionTypeForms().find(fm => fm.id === values.sessionId)?.forms?.[values.formId];
        }
        else{
            frm = getSubmissionTypeForms().find(fm => fm.id === formId);
        }
        return frm?.extraFields ?? [];
    }

    const extraFormFields = getFormExtraFields().map(fm => (
        <Form.Item
            key={`extra.${fm.name}`}
            name={['extra', fm.name]}
            label={fm.label}
            rules={[{
                required: fm.required,
                message: `Please enter ${fm.label}`
            }]}
        >
            <Input placeholder={`Enter ${fm.label}`} />
        </Form.Item>
    ));

    useEffect(() => {
        form.setFieldsValue({ formId: getSubmissionTypeForms().find(fm => fm.id === values.formId)?.name || ''});
    }, [form, submissionType, getSubmissionTypeForms, values.formId]);

    return (
        <>
            <Space className='form-instruction'>
                <Typography.Text>Please fill in the following fields to generate a QR code.</Typography.Text>
            </Space>
            <Form
                form={form}
                initialValues={values}
                onFinish={onFormSubmit}
                layout='horizontal'
                labelAlign='left'
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 14 }}
                className='full-width full-height pad-hori'
            >
                {extraFormFields}
                <Form.Item wrapperCol={{ span: 19 }}>
                    <Flex align='center' justify='flex-end' gap={8}>
                        {onBackClick && <Button className='border-radius' onClick={onBackClick}>Back</Button>}
                        <Button type="primary" className='border-radius' htmlType="submit">Next</Button>
                    </Flex>
                </Form.Item>
            </Form>
        </>
    )
}