import { FileSearchOutlined, QrcodeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { ORG_FEATURES, ROUTES } from "../../constants";
import { AckDetailsView, AcknowledgementsView } from "../Acknowledgements";
import { Navigate, Route } from 'react-router-dom';
import { ReactElement } from "react";
import { MenuProps } from "antd";
import { QrCodesView } from "../QrCodes";
import { AssetDetailsView, AssetsView } from "../Assets";

type MenuItems = MenuProps['items'];

type ComponentFunction = () => ReactElement;

type RouterDetail = {
    orgFeature: string,
    route: string,
    label?: string,
    icon?: ComponentFunction,
    component: ComponentFunction,
    subRoutes?: RouterDetail[],
    listInNavigation?: boolean
}

const ROUTE_DEATIALS_LIST: RouterDetail[] = [
    {
        orgFeature: ORG_FEATURES.ACKNOWLEDGEMENT,
        route: ROUTES.ACKNOWLEDGEMENTS,
        label: 'Acknowledgements',
        icon: () => <UnorderedListOutlined />,
        component: () => <AcknowledgementsView />,
        listInNavigation: true,
        subRoutes: [
            {
                orgFeature: ORG_FEATURES.ACKNOWLEDGEMENT,
                route: ":userId/:submissionId",
                component: () => <AckDetailsView />
            }
        ]
    },
    {
        orgFeature: ORG_FEATURES.ASSETS,
        route: ROUTES.ASSETS,
        label: 'Assets',
        icon: () => <FileSearchOutlined />,
        component: () => <AssetsView />,
        listInNavigation: true,
        subRoutes: [
            {
                orgFeature: ORG_FEATURES.ASSETS,
                route: ":userId/:submissionId",
                component: () => <AssetDetailsView />
            }
        ]
    },
    {
        orgFeature: ORG_FEATURES.QR_CODE_GENERATION,
        route: ROUTES.QR_CODES,
        label: 'QR Codes',
        icon: () => <QrcodeOutlined />,
        component: () => <QrCodesView />,
        listInNavigation: true
    }
];

const getActiveRoutes = (features: string[]) => (
    ROUTE_DEATIALS_LIST.filter(route => features.includes(route.orgFeature))
);

const getRoutePath = (routePath: string, parentRoutePath: string | undefined) => (
    parentRoutePath ? `${parentRoutePath}/${routePath}` : routePath
)

const getRoutes = (routeDetails: RouterDetail[], parentRoutePath: string | undefined = undefined): ReactElement[] => {
    const routes: ReactElement[] = [];
    routeDetails.forEach(route => {
        const routePath = getRoutePath(route.route, parentRoutePath);
        routes.push(< Route key={routePath} path={routePath} element={route.component()} />);
        const subRoutes = getRoutes(route.subRoutes ?? [], routePath);
        if (subRoutes.length > 0) {
            routes.push(...subRoutes);
        }
    });
    return routes;
}

export const getOrgRouteViews = (features: string[]): ReactElement[] => {
    const activeRoutes = getActiveRoutes(features);
    const routes = getRoutes(activeRoutes);
    if (activeRoutes.length > 0) {
        routes.push(<Route key="all" path="*" element={<Navigate to={activeRoutes[0].route} />} />);
    }
    return routes;
}

const getMenuItems = (routeDetails: RouterDetail[], parentRoutePath: string | undefined = undefined): MenuItems => {
    const menuItems: MenuItems = [];
    routeDetails.filter(route => route.listInNavigation).forEach(route => {
        const routePath = getRoutePath(route.route, parentRoutePath);
        const subRoutes = getMenuItems(route.subRoutes ?? [], routePath);
        menuItems.push({
            key: routePath,
            icon: route.icon ? route.icon() : <></>,
            label: route.label,
            children: subRoutes && subRoutes.length > 0 ? subRoutes : undefined
        });
    })
    return menuItems;
}

export const getOrgRouteMenuItems = (features: string[]): MenuItems => {
    return getMenuItems(getActiveRoutes(features));
}

const pathPartCount = (path: string): number => path.split('/').length;

const routerPathBeforeParams = (routerPath: string) => routerPath.split('/:')[0];

const isRouterPathMatch = (routerPath: string, matchingPath: string): boolean => {
    return pathPartCount(matchingPath) === pathPartCount(routerPath) &&
        matchingPath.startsWith(routerPathBeforeParams(routerPath));
}

const getMatchingRoute = (routes: RouterDetail[], matchingPath: string, parentRoutePath: string | undefined = undefined): string | undefined => {
    for (const route of routes) {
        const routePath = getRoutePath(route.route, parentRoutePath);
        if (isRouterPathMatch(routePath, matchingPath)) {
            return routerPathBeforeParams(routePath);
        }
        const foundPath = getMatchingRoute(route.subRoutes ?? [], matchingPath, routePath);
        if (foundPath) {
            return foundPath;
        }
    }

    return undefined
}

export const findMatchingRoute = (features: string[], path: string): string | undefined => {
    return getMatchingRoute(getActiveRoutes(features), path);
}