import { OrgSchemas } from "../types";
import { toDateTimeString, toTimeString } from "./datetime";

export const getFileExtension = (fileName: string): string | undefined => fileName.split('.').pop()?.toLowerCase();

const IMG_EXT = ['png', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'heic'];

export const removeUrlQueryParams = (url: string) => {
    const paths = url.split('?');
    return paths[0];
}

export const isImage = (fileName: string): boolean => {
    const ext = getFileExtension(removeUrlQueryParams(fileName));
    if (!ext) {
        return false;
    }
    return IMG_EXT.includes(ext.toLowerCase());
}

export const getFileFriendlyName = (fileName: string) => {
    return removeUrlQueryParams(fileName).split('/').pop();
}

export const toTitleCase = (text: string) => {
    return text.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
}

export const getViewMenuItems = (orgForms: OrgSchemas, submissionType: string) => {
    return orgForms[submissionType]?.map(form => ({
        key: form.id,
        label: form.name ?? ''
    })) ?? [];
}

export const getFormSummaryFields = (orgForms: OrgSchemas, submissionType: string,formId: string| undefined) => {
    if(!formId){
        return [];
    }
    return orgForms[submissionType]?.find(form => form.id === formId)?.summaryFields?.map(field => { return { ...field, key: field.name}}) ?? [];
}

export const getSummaryFieldValue = (type: string, value: any): string => {
    switch (type) {
        case 'phone':
            return value?.number && value?.dialCode ? `${value.dialCode} ${value.number}` : '-';
        case 'date':
            return toDateTimeString(value);
        case 'time':
            return toTimeString(value);
        default:
            return value ? value : '-';
    }
}