import { Modal, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from 'react'
import { KeyValues, OrgSchema, OrgSchemas } from "../../../types";
import FormView from "./Form";
import QrView from "./QrView";
import { FORM_TYPES } from "../../../constants";


type PropsType = {
    isOpen: boolean;
    form: OrgSchema;
    onClose: () => void;
    schema: OrgSchemas;
    orgId: string
}

enum QrCodeView {
    FormView,
    QRCodeView
}

export default function QrCodeModal({ isOpen, form, onClose, schema, orgId }: PropsType) {
    const [uiView, setUiView] = useState<QrCodeView>(QrCodeView.QRCodeView);
    const [formValues, setFormValues] = useState<KeyValues>({});
    const [sessionFormExtraFields, setSessionFormExtraFields] = useState<KeyValues>({});
    const [showTabQrView, setShowTabQrView] = useState<boolean>(false);
    const [formSchema, setFormSchema] = useState<OrgSchema>();

    useEffect(() => {
        const currentFormSchema = schema[form.submissionType ?? '']?.find(fm => fm.id === form.id);
        setFormSchema(currentFormSchema);
    }, [form, schema]);

    const hasExtraFields = useCallback(() => formSchema?.extraFields?.length,
        [formSchema]);

    useEffect(() => {
        setUiView(hasExtraFields() ? QrCodeView.FormView : QrCodeView.QRCodeView);
    }, [hasExtraFields]);

    const onFormSubmit = (values: KeyValues) => {
        setFormValues(values);
        setUiView(QrCodeView.QRCodeView)
    };

    const onSessionFormSubmit = (values: KeyValues) => {
        setSessionFormExtraFields({
            ...sessionFormExtraFields,
            [values.formId]: values,
        });
        setShowTabQrView(true);
    };

    const getSessionFroms = () => {
        const sessionForms = [];
        let currentSessionForm = formSchema?.forms?.[formSchema?.startFormId ?? ''];
        while (currentSessionForm) {
            const { id, path, name, action, extraFields } = currentSessionForm;
            sessionForms.push({
                formId: id,
                formSchemaUrl: path,
                formName: name,
                sessionId: formSchema!.id,
                sessionFormName: formSchema!.name,
                extraFields,
                action,
                type: formSchema?.type,
                submissionType: form?.submissionType
            });
            currentSessionForm = currentSessionForm.next ? formSchema?.forms?.[currentSessionForm.next] : null;
        }
        return sessionForms;
    }

    const getTabBody = (values: any) => {
        return (
            (values.extraFields?.length && !showTabQrView) ?
                <FormView
                    values={{
                        ...values,
                        submissionType: form.submissionType,
                        ...(sessionFormExtraFields[values.formId] ?? {})
                    }}
                    schema={schema}
                    onSubmit={onSessionFormSubmit}
                    onBackClick={hasExtraFields() ? () => setUiView(QrCodeView.FormView) : undefined}
                /> :
                <QrView
                    key={values.formId}
                    orgId={orgId}
                    values={{
                        formId: values.formId,
                        sessionId: values.sessionId,
                        sessionFormName: values.sessionFormName,
                        type: values.type,
                        action: values.action,
                        submissionType: values.submissionType,
                        formSchemaUrl: values.formSchemaUrl,
                        formName: values.formName,
                        extra: {
                            ...(sessionFormExtraFields[values.formId]?.extra ?? {}),
                            ...formValues.extra
                        }
                    }}
                    schema={schema}
                    onBackClick={
                        values.extraFields?.length ? () => setShowTabQrView(false) :
                            (hasExtraFields() ? () => setUiView(QrCodeView.FormView) : undefined)
                    } 
                />);
    }

    const getTabQrView = () => {
        const sessionForms = getSessionFroms();
        return (
            <Tabs
                tabPosition='left'
                style={{ height: '100%' }}
                items={sessionForms.map((values) => {
                    return {
                        label: `${values.formName}`,
                        key: values.formId,
                        className: 'qr-tab-body',
                        children: getTabBody(values)
                        ,
                    };
                })}
            />
        );
    }

    const getQRView = () => {
        if (formSchema?.type === FORM_TYPES.SESSION) {
            return getTabQrView();
        }
        return (
            <div className="qr-tab-body">
                <QrView
                    key={form?.id}
                    schema={schema}
                    orgId={orgId}
                    values={{
                        formId: form.id,
                        submissionType: form?.submissionType,
                        formSchemaUrl: formSchema?.path,
                        extra: formValues.extra
                    }}
                    onBackClick={hasExtraFields() ? () => setUiView(QrCodeView.FormView) : undefined}
                />
            </div>
        );
    }

    const body =
        uiView === QrCodeView.QRCodeView ?
            getQRView() :
            (<div className="qr-tab-body">
                <FormView
                    values={{ ...formValues, formId: form.id, submissionType: form.submissionType }}
                    schema={schema}
                    onSubmit={onFormSubmit}
                />
            </div>);

    return (
        <Modal
            className="qr-modal"
            centered
            title={form.name ?? ''}
            width={900}
            open={isOpen}
            onCancel={onClose}
            footer={null} >
            <div className="modal-content">
                {body}
            </div>
        </Modal >
    )
}