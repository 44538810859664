import React, { useContext, useEffect, useState } from 'react';
import { MainView, LoginView } from './views';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { authContext } from './context/auth';
import { isSessionAuthenticated } from './utils';
import { Space } from 'antd';
import { AppLogo, ScreenLoader } from './components';
import { ROUTES } from './constants';

function App() {
  const { authState, dispatch } = useContext(authContext);
  const { user } = authState;
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => setAuthenticated(!!user), [user]);

  useEffect(() => {
    isSessionAuthenticated(dispatch).then(authenticated => {
      setAuthenticated(authenticated);
    })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  if (isLoading || (isAuthenticated && !user)) return (
    <Space.Compact direction='vertical' className='content-centered overflow-hidden'>
      <AppLogo />
      <ScreenLoader/>
    </Space.Compact>
  )

  return (
    <BrowserRouter>
      <Routes>
        {!isAuthenticated && <Route path={ROUTES.LOGIN} element={<LoginView />} />}
        {!isAuthenticated && <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />}
        {isAuthenticated && <Route path='*' element={<MainView />} />}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
