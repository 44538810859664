import { Auth } from "aws-amplify";
import { ACTIONS, DispatchType } from "../context/auth";
import { getApi } from "./api";
import { OrgSchemas } from "../types";
import { AUTHORIZED_ROLES } from "../constants";

export const isSessionAuthenticated = async (dispatch: DispatchType) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        postLogin(user, dispatch);
        return true;
    } catch (e) {
        console.log(e);
    }
    return false;
}

export const signIn = async (username: string, password: string, dispatch: DispatchType, newPassword?: string) => {
    try {
        dispatch({ type: ACTIONS.USER_SIGNING_IN, payload: true });
        let user = await Auth.signIn(username.trim(), password.trim());
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (!newPassword) {
                return ('NEW_PASSWORD_REQUIRED');
            }
            user = await Auth.completeNewPassword(user, newPassword.trim());
            postLogin(user, dispatch);
        } else {
            postLogin(user, dispatch);
        }
    } catch (err: any) {
        console.log('ERR:', err);
        dispatch({ type: ACTIONS.USER_SIGNING_IN, payload: false });
        return err.message ? err.message : "Error occured";
    }
}

const getOrgFeatures = async (orgId: string): Promise<string[]> => {
    return getApi<string[]>(`/org-features/${orgId}`)
}

const getOrgForms = async (orgId: string): Promise<OrgSchemas[]> => {
    return getApi<OrgSchemas[]>(`/form-summary-fields/${orgId}`);
}

const postLogin = async (user: any, dispatch: DispatchType) => {
    try {        
        dispatch({ type: ACTIONS.USER_SIGN_IN_ERROR, payload: null });
        dispatch({ type: ACTIONS.USER_SIGNING_IN, payload: true });
        const payload = user.getSignInUserSession().getIdToken().payload;
        const groups = payload['cognito:groups'] || [];
        if(!groups.includes(AUTHORIZED_ROLES.ALDO_ADMIN)){            
            dispatch({ type: ACTIONS.USER_SIGN_IN_ERROR, payload: 'You are not authorized to use this app' });
            await logout(dispatch);
            return;
        }
        const features = await getOrgFeatures(user.attributes['custom:orgId']);
        const orgForms = await getOrgForms(user.attributes['custom:orgId']);
        dispatch({ type: ACTIONS.ORG_FEATURES_LOADED, payload: features });
        dispatch({ type: ACTIONS.ORG_FORMS_LOADED, payload: orgForms });
        dispatch({ type: ACTIONS.USER_SIGNED_IN, payload });
    } finally {
        dispatch({ type: ACTIONS.USER_SIGNING_IN, payload: false });
    }
}

export const logout = async (dispatch: DispatchType) => {
    try {
        await Auth.signOut();
        dispatch({ type: ACTIONS.USER_SIGNED_IN, payload: null });
    }
    catch (err) {
        console.log('ERR:', err);
    }
}